@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Orbitron-Light'), local('Orbitron-Regular'), url(https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/fonts/Orbitron/Orbitron-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Light';
  font-weight: 300;
  font-display: swap;
  src: local('Lato-Light'), url(https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/fonts/Lato/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  font-weight: 400;
  font-display: swap;
  src: local('Lato-Regular'), url(https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: #29b6f6;
}

#root {
  display: flex;
  height: 100%;
  width: 100vw;
}

.page-container {
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
}
